define(["require", "exports", "./live-session-components.interface"], function (require, exports, live_session_components_interface_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    // eslint-disable-next-line import/no-anonymous-default-export
    exports.default = {
        app: {
            isLoading: true,
            openWelcWizard: false,
            currPath: '/',
            lastPath: '/',
            messages: [],
            historyPath: ['/home'],
            refs: {},
            loadingSubscriptions: false,
            isFilterChipActivated: false,
            showLoader: false,
            isIframe: false,
            isSyndicatorUser: false,
            isSampleMode: false,
            showHeader: true,
            showFooter: true,
            showTermsAndConditions: true,
            showSyndicatorHeader: false,
            currentProfile: 'ou',
            switchedProfile: false,
            storyScripts: [],
            showTermsAndConditionsPopup: false,
            termsAndConditionsId: 0,
            profiles: []
        },
        motd: {
            visible: false,
            icon: '',
            header: '',
            body: '',
            detailUrl: '',
            updatedAt: null,
            expiresAt: null
        },
        header: {
            menuSelected: 'home',
            input: {}
        },
        search: {
            input: {},
            searchTitle: '',
            selectedChecks: {},
            defaultedChecks: {},
            loadingMore: false,
            whatIOwnActive: false,
            fullItems: {},
            gridView: true,
            Qtlp: {
                page: 1,
                aggs: true,
                size: 9,
                offset: 0,
                highlight: true,
                filters: []
            }
        },
        userProfile: {
            input: {}
        },
        userChart: {
            tags: [],
            lastLogin: new Date().toISOString(),
            metadata: {
                id: '',
                firstName: '',
                lastName: '',
                legacyGuid: '',
                email: ''
            },
            preFilters: {},
            more: {}
        },
        wizard: {
            open: false,
            step: '/wizard/preferences',
            preferenceOptions: {
                areas: [],
                roles: [],
                products: []
            },
            fullProducts: [],
            input: {}
        },
        home: {
            recommended: {},
            newCont: {},
            freeCont: {},
            liveSessions: {},
            continueLearning: {},
            loadedContinueLearning: false,
            lpChannels: [],
            pendingChannels: [],
            homeLoaded: false,
            queries: {
                recommended: {},
                newCont: {},
                freeCont: {},
                liveSessions: {},
                continueLearning: {},
                lpChannels: {}
            }
        },
        favorites: {
            sresult: {},
            gridView: true
        },
        consumption: {
            iframeUrl: '',
            contentHeight: '100%',
            contentPage: 'course',
            history: [],
            filteredHistory: [],
            achievements: {
                badges: [],
                certifications: [],
                consumptionSummary: {
                    completedCourses: 0,
                    completedEvents: 0,
                    secWatched: 0,
                    videosWatched: 0
                }
            },
            newHistory: {
                offset: 0,
                size: 15,
                total: 0,
                items: []
            },
            loadedConsumption: false,
            loadedAchievements: false,
            isIframeLoading: false,
            loadingHistoryAsync: false,
            communityId: null,
            communityUrl: null
        },
        communities: {
            showBanner: true
        },
        subscriptions: {
            content: {},
            queryIn: [],
            selectedSubsNames: []
        },
        pendingReservations: {
            skipped: [],
            pending: []
        },
        els: {
            offerings: [],
            subscriptions: []
        },
        digitalLibrary: [],
        storiesVariantsLibrary: [],
        story: {
            storyId: '',
            request: {
                pagination: {
                    size: 20,
                    offset: 0
                },
                loadedCarousels: -1,
                carouselSize: 10
            },
            display: {
                filters: {},
                canExpandResults: true,
                carousels: [],
                keyword: '',
                carouselsStatus: []
            },
            content: {
                title: '',
                pillarName: '',
                description: '',
                subscriptionDetailsUrl: '',
                communityUrl: null,
                color: '',
                texture: '',
                friendlyUrlPath: '',
                offering: {},
                carousels: [],
                hits: {
                    hits: [],
                    total: {
                        value: 0
                    }
                },
                aggregations: {}
            },
            loadingMore: false
        },
        exam: {
            metadata: {
                name: '',
                examDuration: 0,
                numberOfQuestions: 0,
                associatedCertifications: '',
                recommendedTraining: '',
                examOverview: '',
                links: [],
                deliveryMode: '',
                examFormat: '',
                passingScore: '',
                additionalInformation: '',
                examSeriesCode: '',
                examTopics: '',
                occData: true,
                description: '',
                buyNowLink: '',
                timezones: [],
                policy: '',
                examRetirementDate: '',
                pageLang: 'en',
                isExamActive: false,
                tId: '20',
                isContainerComponentAssociated: true
            },
            loadedMetadata: false,
            isExamCreditAvailable: false,
            examCredits: [],
            loadedAvailableSlots: false,
            availableSlots: [],
            loadedExamDetails: false,
            loadedUpdateEnrollment: true,
            loadedEnrollILP: true,
            examDetails: {
                isExamBooked: false,
                tnc: '',
                tnc_id: '',
                lastStatus: 'NEW',
                registeredSlots: [],
                isError: false,
                canResumePbtExam: false,
                userExamType: 'R',
                examUrl: '#',
                examCreditExpiryDate: '',
                examEligibility: 'Y',
                hideAdditionalText: false,
                useAsxExamPlayer: false,
                useProctorio: false
            },
            loadedExamDetailStatus: false,
            examDetailStatus: {
                exams: [],
                totalCredits: 0,
                availableCredits: 0,
                appliedCredits: 0,
                expiredCredits: 0
            },
            loadedMyExam: false,
            myExam: {},
            privateEventErrMsg: '',
            isValidPrivateEventCode: null,
            newValueLoaded: true
        },
        trackingContext: {
            session: '',
            learner: {
                guid: '',
                id: '',
                isGuest: false
            },
            sourcePage: '',
            offerings: [],
            tenant: '',
            orders: [],
            learningPath: {
                containerId: '',
                title: ''
            },
            container: {
                containerId: '',
                title: '',
                type: ''
            },
            component: {
                componentId: '',
                title: '',
                type: ''
            },
            search: {
                keyword: '',
                filters: []
            },
            isSubscriber: false
        },
        termsAndConditions: {
            tacStatus: false,
            tacText: null,
            tacHeading: '',
            isTacLoaded: false,
            tacId: ''
        },
        feedback: {
            feedbackData: {},
            loadedFeedbackData: false,
            loadedSubmitFeedback: true
        },
        metadata: {
            themes: {}
        },
        learningPath: {
            learningPathData: {},
            isLpPageDataLoaded: false,
            isLpUserDataLoaded: false,
            isLpMoocDataLoaded: false,
            loadingEnrollILP: false,
            loadedEnrollLP: false,
            savedBadge: false
        },
        learnerRoleProfile: {
            id: 'string',
            name: 'OU',
            description: 'Oracle University',
            isDefault: true,
            libraries: [
                {
                    id: 'string',
                    index: 'string'
                }
            ],
            sortNumber: 0,
            contentOwners: [
                {
                    id: 'string',
                    name: 'string',
                    legacyLearnOwnerId: 0
                }
            ],
            isVisible: true,
            friendlyUrlPath: 'ou',
            frontend: {
                headerBoldTitle: {
                    default: '',
                    guest: ''
                },
                headerTitle: {
                    default: 'Oracle University',
                    guest: 'Oracle University'
                },
                bannerTitle: {
                    // eslint-disable-next-line no-template-curly-in-string
                    default: 'Welcome, ${firstName}',
                    guest: ''
                },
                bannerSubtitle: {
                    // eslint-disable-next-line no-template-curly-in-string
                    default: 'Your personal digital learning portal',
                    guest: 'Welcome to MyLearn - Your Personal, Digital Cloud Learning Portal'
                },
                bannerDescription: {
                    default: 'Explore and sample content in the digital library, add topics of interest and start learning!  We will provide recommendations to help guide your learning journey, track your progress and accomplishments.',
                    guest: 'MyLearn is a customizable, personalized training portal that enhances and speeds your learning of Oracle Cloud. Find the training you want, fast. Easily track your progress. Get course recommendations that best match your learning goals. Get it all - and more - on any device, from any location.'
                },
                bannerImage: {
                    default: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/welcome-banner.png',
                    guest: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/welcome-banner.png'
                },
                bannerBackgroundImage: {
                    default: 'https://education.oracle.com/file/general/welcome-banner-bg-sm.png?v2',
                    guest: 'https://education.oracle.com/file/general/welcome-banner-bg-sm.png?v2'
                },
                bannerBlackText: {
                    default: false,
                    guest: false
                },
                showDiscoverYourPath: {
                    default: false,
                    guest: true
                },
                showMySubscriptions: {
                    default: true,
                    guest: false
                },
                showExams: {
                    default: true,
                    guest: false
                },
                exploreOurCloudLibraryTitle: {
                    default: 'Explore our Cloud Library',
                    guest: 'Explore our Cloud Library'
                },
                library: {
                    'Featured Content': {
                        icon: 'oj-ux-ico-instructor-training',
                        products: {
                            'Certification Paths': {
                                metadata: {
                                    url: '/search/?searchTitle=Certifications&filters=Type-certification'
                                },
                                sortNumber: 1
                            },
                            'Free Learning': {
                                metadata: {
                                    url: '/search/?filters=Type-free'
                                },
                                sortNumber: 2
                            }
                        }
                    }
                },
                onboardingSkipWelcomePage: {
                    default: true,
                    guest: true
                },
                onboardingLogo: {
                    default: '',
                    guest: ''
                },
                onboardingTitle: {
                    default: 'Welcome to Oracle MyLearn',
                    guest: 'Welcome to Oracle MyLearn'
                },
                onboardingSubTitle: {
                    default: 'We ensure your Oracle learning journey is effortless and successful.',
                    guest: 'We ensure your Oracle learning journey is effortless and successful.'
                },
                onboardingBenefits: {
                    default: 'Learn Oracle products from experts instructors',
                    guest: 'Learn Oracle products from experts instructors'
                },
                onboardingProductsOwner: {
                    default: '',
                    guest: ''
                },
                onboardingShowFeaturesAndBenefits: {
                    default: true,
                    guest: true
                },
                onboardingStepsSelectPreferencesText: {
                    default: 'Personalize experience',
                    guest: 'Personalize experience'
                },
                onboardingWelcomeStartBtnText: {
                    default: 'Start',
                    guest: 'Start'
                },
                onboardingShowAccessComponent: {
                    default: 'product-pillars',
                    guest: 'product-pillars'
                },
                onboardingPreferencesSkipBtnText: {
                    default: 'Skip',
                    guest: 'Skip'
                },
                onboardingPreferencesTagsColor: {
                    default: '#1e5b72',
                    guest: '#1e5b72'
                },
                onboardingAllSetStartButton: {
                    default: 'Start Learning',
                    guest: 'Start Learning'
                },
                onboardingReviewPreferencesCustomText: {
                    default: 'View your selections and our recommendations on your home page.',
                    guest: 'View your selections and our recommendations on your home page.'
                },
                preferencesTitle: {
                    default: 'Select your interests',
                    guest: 'Select your interests'
                },
                preferencesProductsTitle: {
                    default: '',
                    guest: ''
                },
                preferencesProducts: {
                    default: 'Products',
                    guest: 'Products'
                },
                preferencesAreasTitle: {
                    default: '',
                    guest: ''
                },
                preferencesAreas: {
                    default: 'Areas',
                    guest: 'Areas'
                },
                preferencesRolesTitle: {
                    default: '',
                    guest: ''
                },
                preferencesRoles: {
                    default: 'Roles',
                    guest: 'Roles'
                },
                preferencesLearningGoalsGetCertified: {
                    default: 'Get Certified',
                    guest: 'Get Certified'
                },
                preferencesLearningGoalsLearnNewFeatures: {
                    default: 'Learn New Features',
                    guest: 'Learn New Features'
                },
                preferencesLearningGoalsImproveSkills: {
                    default: 'Improve Overall Skills',
                    guest: 'Improve Overall Skills'
                },
                searchNoResultsTitle: {
                    default: 'No results were found.',
                    guest: 'No results were found.'
                },
                searchNoResultsSubtitle: {
                    default: 'We suggest you try the following to help find what you’re looking for:',
                    guest: 'We suggest you try the following to help find what you’re looking for:'
                },
                searchNoResultsSteps: [
                    {
                        default: 'Check the spelling of your keyword search or your filter combination',
                        guest: 'Check the spelling of your keyword search or your filter combination'
                    },
                    {
                        default: 'Use synonyms for the keyword you typed, for example, try “application” instead of “software”',
                        guest: 'Use synonyms for the keyword you typed, for example, try “application” instead of “software”'
                    },
                    {
                        default: 'Start a new search',
                        guest: 'Start a new search'
                    }
                ],
                searchNoResultsLinkText: {
                    default: '',
                    guest: ''
                },
                searchNoResultsLinkUrl: {
                    default: '',
                    guest: ''
                },
                discoverYourPath: {
                    requiredUserTags: [],
                    values: [
                        {
                            title: 'Get OCI Certified',
                            description: 'Get certified on Oracle Cloud Infrastructure and other cloud services for free.',
                            image: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/feature-img-1.png',
                            url: '/story/35644'
                        },
                        {
                            title: 'Start Learning HCM',
                            description: 'Start learning now with in-depth training on Oracle Human Capital Management (HCM).',
                            image: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/feature-img-2.png',
                            url: '/story/44447'
                        },
                        {
                            title: 'Get Oracle Certified',
                            description: ' Earn a well-respected credential to demonstrate expertise on OCI and other cloud services.',
                            image: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/feature-img-3.png',
                            url: '/search/?searchTitle=Get%20Oracle%20Certified&filters=Type-certification'
                        },
                        {
                            title: 'Explore Free Learning',
                            description: 'View all free training from Oracle University.',
                            image: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/feature-img-4.png',
                            url: '/search/?searchTitle=Explore Free Learning&filters=Type-free&filters=Format-Learning Path'
                        }
                    ]
                },
                discoverYourPathTitle: {
                    default: 'Discover your path',
                    guest: 'Discover your path'
                },
                skillUpTopics: {
                    title: 'Skill up on popular topics',
                    image: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/Discovery.png',
                    categories: [
                        {
                            title: 'Cloud Applications (SaaS)',
                            items: [
                                { title: 'Onboarding Oracle Cloud Applications' },
                                { title: 'Core HR' },
                                { title: 'Benefits' },
                                { title: 'Compensation' },
                                { title: 'Inventory' },
                                { title: 'Manufacturing' },
                                { title: 'Supply Chain Planning' },
                                { title: 'Warehouse Management' },
                                { title: 'Eloqua' },
                                { title: 'Responsys' },
                                { title: 'Maxymiser' },
                                { title: 'Infinity' },
                                { title: 'Sales Force Automation' },
                                { title: 'Incentive Compensation' },
                                { title: 'B2B Service' },
                                { title: 'Enterprise Data Management' },
                                { title: 'Assets' },
                                { title: 'Expenses' },
                                { title: 'General Ledger' },
                                { title: 'Project Execution Management' },
                                { title: 'Recruiting Cloud' },
                                { title: 'Goal and Performance Management' },
                                { title: 'Workforce Compensation' }
                            ]
                        },
                        {
                            title: 'Oracle Cloud Infrastructure (OCI)',
                            items: [
                                { title: 'Blockchain Platform' },
                                { title: 'Container Engine for Kubernetes' },
                                { title: 'SOA Cloud' },
                                { title: 'Content and Experience' },
                                { title: 'WebCenter Portal' },
                                { title: 'Autonomous Database' },
                                { title: 'Database Cloud' },
                                { title: 'Exadata Cloud at Customer' },
                                { title: 'Big Data Cloud' },
                                { title: 'Analytics Cloud' },
                                { title: 'OCI Data Science' },
                                { title: 'Internet of Things' }
                            ]
                        },
                        {
                            title: 'Other Technologies',
                            items: [
                                { title: 'Database' },
                                { title: 'Data Warehouse' },
                                { title: 'Big Data' },
                                { title: 'Oracle Business Intelligence' },
                                { title: 'Identity Management' },
                                { title: 'MySQL' },
                                { title: 'Java SE 11' },
                                { title: 'Oracle Exadata' },
                                { title: 'Oracle Linux' },
                                { title: 'Oracle Exalogic Elastic Cloud' },
                                { title: 'Hyperion' },
                                { title: 'Essbase' },
                                { title: 'Crystal Ball' },
                                { title: 'EBS and Oracle Cloud' },
                                { title: 'P6' },
                                { title: 'Oracle Banking Platform' },
                                { title: 'Oracle Hospitality Reporting and Analytics' },
                                { title: 'Retail Insights' },
                                { title: 'Oracle Utilities Application Framework' },
                                { title: 'PeopleSoft Tools' }
                            ]
                        }
                    ],
                    requiredUserTags: []
                },
                showNotificationPreferences: {
                    default: true,
                    guest: false
                },
                storyShowProductFilter: {
                    default: false,
                    guest: false
                },
                searchProduct: {
                    default: 'Product',
                    guest: 'Product'
                },
                searchArea: {
                    default: 'Area',
                    guest: 'Area'
                },
                searchShowLibraryProducts: {
                    default: true,
                    guest: true
                },
                searchFilterSortNumbers: {},
                showCommunity: {
                    default: true,
                    guest: false
                },
                containerCardUseLibraryLabel: {
                    default: false,
                    guest: false
                },
                dashboardShowCertifications: {
                    default: true,
                    guest: true
                },
                dashboardAchievementsListTitle: {
                    default: 'Other Achievements',
                    guest: 'Other Achievements'
                },
                dashboardAchievementsCertifiedButtonText: {
                    default: 'Become Oracle Certified',
                    guest: 'Become Oracle Certified'
                },
                dashboardAchievementsCertifiedButtonUrl: {
                    default: '/search/?searchTitle=Certifications&filters=Type-certification',
                    guest: '/search/?searchTitle=Certifications&filters=Type-certification'
                },
                dashboardAchievementsExplorerButtonText: {
                    default: 'Become an Explorer',
                    guest: 'Become an Explorer'
                },
                dashboardAchievementsExplorerButtonUrl: {
                    default: '/search?searchTitle=Become%20an%20Explorer&filters=Format-Learning%20Path&filters=Type-free',
                    guest: '/search?searchTitle=Become%20an%20Explorer&filters=Format-Learning%20Path&filters=Type-free'
                },
                dashboardAchievementsShowShareBanner: {
                    default: true,
                    guest: true
                },
                lPCustomChip: {
                    label: '',
                    className: '',
                    offeringIds: []
                },
                showDropDownCommunityUrl: {
                    default: false,
                    guest: false
                },
                helpSupportUrl: {
                    default: 'https://help-education.oracle.com/pls/apex/f?p=107:22:::::P_FROM:MyLearn',
                    guest: 'https://help-education.oracle.com/pls/apex/f?p=107:22:::::P_FROM:MyLearn'
                },
                contactUsUrl: {
                    default: 'https://education.oracle.com/OUcontactUs',
                    guest: 'https://education.oracle.com/OUcontactUs'
                },
                askTheInstructorDrawerArticleCourse: {
                    default: 'ou-component',
                    guest: 'ou-component'
                },
                freeContentActivationUrl: {
                    default: '',
                    guest: ''
                },
                termsAndConditionsUrl: {
                    default: 'https://www.oracle.com/corporate/contracts/software-hardware/software.html#university',
                    guest: 'https://www.oracle.com/corporate/contracts/software-hardware/software.html#university'
                },
                askTheInstructorDrawerArticleCourseLink: {
                    default: '',
                    guest: ''
                },
                customTypeFilterOptions: [
                    'Certification',
                    'New'
                ],
                searchCustomTypeFilterOptions: [
                    'Certification',
                    'Free',
                    'New',
                    'Paid',
                    'What I Own'
                ],
                promotionPopUpImage: {
                    default: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/oci-certification-promotion.png',
                    guest: 'https://objectstorage.us-phoenix-1.oraclecloud.com/p/c3pofC7CHkSqKCRIe7q91rfgM9hXgQQW-GN-CtR9tbzkW4HIN0H02OJ8xwoeHPO_/n/axdnydvy8b2d/b/BCKMLPHXDEV_IMAGES/o/img/PROD/oci-certification-promotion.png'
                },
                pageTitle: {
                    default: 'Oracle MyLearn',
                    guest: 'Oracle MyLearn'
                },
                commonPreferencesVisible: {
                    default: true,
                    guest: true
                },
                dashboardAboutMeVisible: {
                    default: true,
                    guest: true
                },
                commonLiveSessionsVisible: {
                    default: false,
                    guest: false
                },
                iridizeAppId: {
                    default: 'M5w910ZERQK3PRVriVMoNw',
                    guest: 'M5w910ZERQK3PRVriVMoNw'
                },
                navbarToDoDrawerVisible: {
                    default: false,
                    guest: false
                },
                skipOrderReservationAvailable: {
                    default: false,
                    guest: false
                },
                credentialsTitle: {
                    default: 'Oracle Certification Credentials',
                    guest: 'Oracle Certification Credentials'
                },
                unauthorizedDialogTitle: {
                    default: '',
                    guest: 'Returning User?'
                },
                unauthorizedDialogCompanyPassUrl: {
                    default: '',
                    guest: ''
                },
                showCoursePageAdditionalInformation: {
                    default: true,
                    guest: true
                },
                profileHeaderMobileMenuLabel: {
                    default: 'OU',
                    guest: 'OU'
                },
                profileHeaderMobileMenuLabelStyle: {
                    default: '',
                    guest: ''
                },
                profileHeaderMobileMenuIcon: {
                    default: '',
                    guest: ''
                },
                profileHeaderMobileMenuIconStyle: {
                    default: 'margin-left: -8px',
                    guest: 'margin-left: -8px'
                },
                unauthorizedDialogStaticText: {
                    default: '',
                    guest: ''
                },
                showContinueButtonOnActivationError: {
                    default: true,
                    guest: true
                },
                learningPathShowCoursesWithNewRelatedValue: {
                    default: false,
                    guest: false
                },
                showTypeLiveEventsPage: {
                    default: true,
                    guest: true
                }
            }
        },
        course: {
            coursePageData: {
                id: '',
                name: '',
                description: '',
                typeId: '',
                tenantId: '',
                communityId: '',
                showPostOnCommunity: '',
                gcc: '',
                audience: ['All Users'],
                showAskInstructor: false,
                showFeedback: false,
                showAskInstructorYN: 'N',
                showFeedbackYN: 'N',
                subscriptionTypeId: 0
            },
            courseFlags: {
                accessEkit: false,
                allowEkitDownload: false,
                suppressLab: false,
                accessLab: false,
                accessAskInstructor: false,
                accesstype: '',
                elsGCC: '',
                hasAccess: false,
                hasEls: false,
                ilaOrderId: ''
            },
            coursePageUserData: {
                id: '',
                typeId: '',
                isFavorite: '',
                totalProgress: '',
                modules: []
            },
            loadingCourse: true,
            loadingUserData: false,
            loadedFlagsData: false,
            loadingEnrollILP: false,
            loadedCourseData: false,
            loadedUserData: false,
            isCourseReady: false,
            currentComponentCompleted: false,
            offering: [],
            videoLoading: false,
            showUnauthorizedDialog: false,
            showSampleModeDialog: false,
            showTimeConsumedDialog: false,
            sampleModeTimeUp: false,
            isSubscriber: false,
            authOfferings: [],
            currentSelectedComponent: {},
            currentSelectedComponentIndex: {
                moduleIndex: 0,
                componentIndex: 0
            },
            compIndexMap: {},
            renderType: 'component',
            courseConsumptionContext: {
                itemId: '',
                type: '',
                learnerId: '',
                itemName: '',
                status: 'Not Started',
                percentage: 0,
                isSubscriber: 'N',
                lastPosition: 0,
                totalSecondsWatched: 0,
                progress: 0,
                percVideoViewed: 0,
                more: {
                    bcEvent: ''
                }
            },
            relatedLearningPaths: {
                learningPaths: []
            }
        },
        labAssignment: {
            ilAssignmentData: {
                offeringLabDuration: null,
                extensionCount: null,
                subExpiryFlag: false,
                subExpiryDate: '',
                eventStartDate: '',
                eventEndDate: '',
                currentDate: '',
                pstDate: '',
                paasStartDate: '',
                paasEndDate: '',
                paasFlag: false,
                salesEmpFlag: false,
                fusionQMFlag: false,
                fusionStartDate: '',
                fusionEndDate: '',
                startDate: '',
                endDate: '',
                utcStartDate: '',
                utcEndDate: '',
                fusionFlag: false,
                ofsQMFlag: false,
                orgIdTimezone: '',
                assignmentStatus: '',
                studentTimezone: '',
                assignedDate: '',
                requestStatus: '',
                assignmentId: null,
                completed: '',
                eventExtendCount: null,
                orgEventEndDate: '',
                time: '',
                extendDuration: null,
                extensionFlag: false,
                offeringExtFlag: false,
                bookedFlag: false,
                extendFlag: false,
                duration: null,
                newAssignmentId: null,
                newStartDate: '',
                newEndDate: '',
                error: null,
                message: null
            },
            ilCheckResourceData: {
                eventId: 0,
                guId: '',
                sso: '',
                scActiveYN: '',
                courseId: '',
                orderId: '',
                ilaOrderNumber: '',
                opnFlag: '',
                buildTime: '',
                buildTimeMinute: null,
                labDate: '',
                startDate: '',
                endDate: '',
                durationCount: null,
                region: '',
                timeZone: '',
                error: '',
                message: ''
            },
            addInstantLabResponse: {
                message2: '',
                message: '',
                assignmentId: null,
                error: ''
            },
            extendILResponse: {
                extendFlag: false,
                message: '',
                fusionFlagCheck: false,
                eventId: null,
                outageStartDate: '',
                outageEndDate: '',
                newStartDate: '',
                newEventStartDate: '',
                fusionExtendFlag: false,
                oscExtendFlag: false,
                checkExtendFlag: false,
                ofsMaintenanceCheck: false,
                error: ''
            },
            assignmentData: {
                canBookMultipleLabs: false,
                assignmentStatus: '',
                assignedDate: '',
                requestStatus: '',
                assignmentId: null,
                completed: '',
                error: null,
                message: null
            },
            availableWeeksData: {
                canBookMultipleLabs: false,
                labDate: '',
                availableWeeksBE: null,
                weeksQuantity: null,
                months: [],
                error: null,
                message: null
            },
            addLabResponse: {
                message2: '',
                message: '',
                assignmentId: null,
                error: ''
            },
            assignmentDetails: {
                eventId: null,
                machines: [],
                error: null,
                message: null
            }
        },
        premiumLab: {
            labData: {
                labId: '',
                title: '',
                description: '',
                options: [],
                relatedCourse: {
                    id: '',
                    name: ''
                },
                audience: '',
                eKits: []
            }
        },
        premiumLabUser: {
            lab_yn: false
        },
        liveSession: {
            action: {
                eventStatus: live_session_components_interface_1.EVENT_STATUS.EVENT_NO_ACCESS,
                btnStatus: {}
            },
            sessionDetails: {
                name: '',
                courseName: '',
                description: '',
                additionalDetails: '',
                duration: '',
                isFreeEvent: false,
                capacity: '',
                showInstructorBio: false,
                isEventActive: false,
                isEventCancelled: false,
                canAddToCalendar: false,
                eventToStartInDuration: 0,
                isZoomIntegrated: false,
                zoomLink: '',
                formattedEventStartDate: '',
                formattedEventEndDate: '',
                utcStart: '',
                utcEnd: '',
                eventTimezone: '',
                eventTimezone3: '',
                eventSchedule: {
                    startDate: '',
                    startTime: '',
                    timezone: '',
                    gmtHour: '',
                    durationInHours: 0,
                    durationInDays: 0
                },
                communityUrl: '',
                hasEkits: false,
                surveyRequired: false,
                approvalRequired: false,
                capacityReachedAction: '',
                capacityReached: false,
                element: {
                    enroll_message_waitlist: '',
                    session_footer: '',
                    enroll_message: '',
                    auto_enroll_message: ''
                },
                customContent: {
                    reg_info: ''
                },
                consumptionUserFlags: {
                    accessEkit: false,
                    allowEkitDownload: false,
                    suppressLab: false,
                    accessLab: false,
                    accessAskInstructor: false,
                    accesstype: '',
                    elsGCC: '',
                    hasAccess: false,
                    hasEls: false,
                    ilaOrderId: '',
                    accessLiveEvents: false
                },
                tenantId: live_session_components_interface_1.TenantType.OU,
                hasEventEnded: false
            },
            userData: {
                id: '',
                complete: false,
                enrolled: false,
                hasAccess: false,
                isEventActive: true,
                enrollmentStatus: live_session_components_interface_1.EnrollmentStatus.NONE,
                enrollmentStatusMsg: ''
            },
            loadedSessionData: false,
            loadedUserData: false,
            loadingLiveSession: true,
            loadingEnrollILP: false,
            loadedFlagsData: false,
            loadedRegistrationData: false,
            loadingInProgress: false,
            showUnauthorizedDialog: false,
            showAlertDialog: false,
            alertDialogBody: ''
        }
    };
});
