define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.emptyStoryContent = exports.emptyStoryMetadata = void 0;
    exports.emptyStoryMetadata = {
        id: '',
        title: '',
        description: '',
        subscriptionDetailsUrl: '',
        color: '',
        texture: '',
        friendlyUrlPath: '',
        carousels: [],
        metadata: {},
        requiredUserTags: [],
        communityUrl: null,
        banner: {
            showCommunityButton: true,
            resources: [],
            video: null,
            blackText: false
        },
        bannerBackgroundImage: {
            url: null
        },
        bannerLogoImage: {
            url: null
        },
        bannerVideoImage: {
            url: null
        },
        chipLabel: null,
        additionalLearning: { visible: false },
        filtersMasks: {}
    };
    exports.emptyStoryContent = {
        searchResponse: {
            aggregations: {},
            hits: {
                hits: [],
                total: {
                    relation: 'eq', value: 0
                }
            }
        }
    };
});
