var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
define(["require", "exports", "preact", "react", "../../../model", "../chat.action", "./chat-video-modal"], function (require, exports, preact_1, react_1, model_1, chat_action_1, chat_video_modal_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.MessageSource = void 0;
    chat_video_modal_1 = __importStar(chat_video_modal_1);
    var MessageSource;
    (function (MessageSource) {
        MessageSource["BOT"] = "bot";
        MessageSource["USER"] = "user";
    })(MessageSource || (exports.MessageSource = MessageSource = {}));
    function ConversationMessage(_a) {
        var _b;
        var index = _a.index, message = _a.message, openVideoSrc = _a.openVideoSrc;
        var userProfile = model_1.Backbone.getInstance().state.userProfile;
        var onVideoSrcClick = function (src) {
            openVideoSrc(src);
        };
        var scrollToMessageEnd = function () {
            setTimeout(function () {
                var _a;
                (_a = document.getElementById("messageEnd-".concat(index))) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
            }, 50);
        };
        if (message.source === MessageSource.USER) {
            return ((0, preact_1.h)("div", { className: "oj-flex oj-sm-justify-content-flex-end" },
                (0, preact_1.h)("div", { className: "oj-flex-item chat-message-user-wrapper" }, message.text)));
        }
        return ((0, preact_1.h)("div", { className: "oj-flex oj-sm-flex-wrap-nowrap" },
            (0, preact_1.h)("div", { className: "oj-flex-item oj-sm-flex-initial oj-sm-padding-2x-end" },
                (0, preact_1.h)("div", { className: "chat-message-bot-icon oj-flex oj-sm-justify-content-center oj-sm-align-items-center oj-color-invert" },
                    (0, preact_1.h)("span", { role: "presentation", className: "oj-ux-ico-oracle-chat" }))),
            (0, preact_1.h)("div", { className: "oj-flex-item" },
                (0, preact_1.h)("div", { className: "chat-message-bot-wrapper" },
                    message.text,
                    (((_b = message.references) === null || _b === void 0 ? void 0 : _b.length) || 0) > 0 &&
                        (0, preact_1.h)("div", { className: "oj-sm-margin-4x-top oj-sm-margin-2x-bottom" },
                            (0, preact_1.h)("oj-collapsible", { onojExpand: function () { return scrollToMessageEnd(); } },
                                (0, preact_1.h)("p", { slot: "header", className: "oj-typography-body-sm" }, "View References"),
                                (0, preact_1.h)("div", null,
                                    (0, preact_1.h)("ul", null, 
                                    // @ts-ignore
                                    message.references.map(function (_a, index) {
                                        var title = _a.title, url = _a.url, timestamp = _a.timestamp;
                                        return (0, preact_1.h)("li", { key: "".concat(index) },
                                            (0, preact_1.h)("a", { onClick: function (e) {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    onVideoSrcClick("".concat(url, "?timeStamp=").concat(timestamp.replace(/\.\d{3}/, ''), "&token=").concat(userProfile.accessToken));
                                                } }, title));
                                    }))))),
                    message.typing &&
                        (0, preact_1.h)("div", { className: "oj-flex oj-sm-align-items-center oj-sm-justify-content-center chat-message-typing-wrapper" },
                            (0, preact_1.h)("div", { className: "chat-message-typing" },
                                (0, preact_1.h)("span", null),
                                (0, preact_1.h)("span", null),
                                (0, preact_1.h)("span", null))),
                    (0, preact_1.h)("div", { id: "messageEnd-".concat(index) })))));
    }
    function Chat(_a) {
        var _b = _a.onCloseClick, onCloseClick = _b === void 0 ? function () { } : _b;
        var backbone = model_1.Backbone.getInstance();
        var _c = (0, react_1.useState)(''), inputText = _c[0], setInputText = _c[1];
        var _d = (0, react_1.useState)(false), inputDisabled = _d[0], setInputDisabled = _d[1];
        // modal with video reference
        var _e = (0, react_1.useState)(''), videoRefSrc = _e[0], setVideoRefSrc = _e[1];
        function openModalWithVideoSrc(videoSrc) {
            setVideoRefSrc(videoSrc);
            (0, chat_video_modal_1.openChatVideoModal)();
        }
        //
        var _f = (0, react_1.useState)([
            {
                source: MessageSource.BOT,
                text: 'Hi, I\'m the MyLearn Chatbot, how can I help you?'
            }
        ]), messages = _f[0], setMessages = _f[1];
        var _g = (0, react_1.useState)(), sessionId = _g[0], setSessionId = _g[1];
        var setFocusOnInputText = function () {
            var _a;
            (_a = document.getElementById('chatFooterInputText|input')) === null || _a === void 0 ? void 0 : _a.focus();
        };
        var handleKeyPress = function (event) {
            if (event.key === 'Enter') {
                submitText();
            }
        };
        var scrollToChatConversationEnd = function () {
            setTimeout(function () {
                var _a;
                (_a = document.getElementById('chatConversationEnd')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ block: 'end', behavior: 'smooth' });
            }, 50);
        };
        var clearInput = function () { setInputText(''); };
        var send = function () {
            setInputDisabled(true);
            (0, chat_action_1.createQuery)(inputText, backbone.state.userProfile.accessToken, sessionId)
                .then(function (_a) {
                var output = _a.output, references = _a.references, newSessionId = _a.sessionId;
                setMessages(__spreadArray(__spreadArray([], messages, true), [{ source: MessageSource.USER, text: inputText }, { source: MessageSource.BOT, text: output, references: references }], false));
                setInputDisabled(false);
                if (!sessionId) {
                    setSessionId(newSessionId);
                }
            })
                .catch(function () {
                setInputDisabled(false);
                // remove last typing message
                setMessages(__spreadArray(__spreadArray([], messages, true), [{ source: MessageSource.USER, text: inputText }], false));
            });
            setMessages(__spreadArray(__spreadArray([], messages, true), [{ source: MessageSource.USER, text: inputText }, { source: MessageSource.BOT, text: '', typing: true }], false));
        };
        var submitText = function () {
            send();
            clearInput();
        };
        (0, react_1.useEffect)(function () {
            scrollToChatConversationEnd();
        }, [messages]);
        (0, react_1.useEffect)(function () {
            if (!inputDisabled) {
                setTimeout(function () {
                    setFocusOnInputText();
                }, 500);
            }
        }, [inputDisabled]);
        return ((0, preact_1.h)("div", { className: 'chat-wrapper' },
            (0, preact_1.h)("div", { className: "chat-height-full oj-flex-bar oj-sm-flex-direction-column" },
                (0, preact_1.h)("div", { className: "oj-flex-bar-start chat-header oj-color-invert" },
                    (0, preact_1.h)("div", { className: "oj-flex-bar oj-sm-align-items-center oj-sm-width-full" },
                        (0, preact_1.h)("div", { className: "oj-flex-bar-middle oj-typography-subheading-xs oj-sm-padding-4x" }, "MyLearn Chatbot"),
                        (0, preact_1.h)("div", { className: "oj-flex-bar-end" },
                            (0, preact_1.h)("oj-button", { id: "chatButton", display: "icons", class: "oj-button-sm", chroming: "borderless", onojAction: function () { return onCloseClick(); } },
                                (0, preact_1.h)("span", { slot: "startIcon", className: "oj-ux-ico-close" }))))),
                (0, preact_1.h)("div", { id: "chatConversation", className: "oj-flex-bar-middle chat-conversation" },
                    (0, preact_1.h)("div", { className: "oj-sm-padding-4x" },
                        messages.map(function (message, index) {
                            return ((0, preact_1.h)("div", { className: "oj-sm-margin-2x-bottom", key: "".concat(index) },
                                (0, preact_1.h)(ConversationMessage, { index: index, message: message, openVideoSrc: openModalWithVideoSrc })));
                        }),
                        (0, preact_1.h)("div", { id: "chatConversationEnd", className: "oj-sm-padding-4x-bottom" }))),
                (0, preact_1.h)("div", { className: "oj-flex-bar-end chat-footer" },
                    (0, preact_1.h)("div", { className: "oj-sm-width-full oj-sm-margin-1x" },
                        (0, preact_1.h)("oj-input-text", { id: "chatFooterInputText", placeholder: "Type here ...", autofocus: true, value: inputText, onrawValueChanged: function (e) { return setInputText(e.detail.value); }, onKeyPress: function (e) { return handleKeyPress(e); }, disabled: inputDisabled },
                            (0, preact_1.h)("oj-button", { slot: "end", display: "icons", chroming: "callToAction", class: "oj-button-sm", onojAction: function () { return submitText(); }, disabled: inputDisabled },
                                (0, preact_1.h)("span", { slot: "endIcon", className: "oj-ux-ico-arrow-up", role: "img", title: "email" })))))),
            (0, preact_1.h)(chat_video_modal_1.default, { videoSrc: videoRefSrc, onModalClose: function () { return setVideoRefSrc(''); } })));
    }
    exports.default = Chat;
});
